.heatmap {


    &-container {
        position: relative;

    }

    &-map {
        display: block;
        width: 100%;


    }

    &-heat-points {

        background-size: cover;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 1rem;
        padding: 1rem;
        position: absolute;
        top: 0;
        overflow: hidden;
        background-blend-mode: multiply;
        background-repeat: no-repeat;

        @media (max-width: 800px) {

            .ap {
                scale: .5;
                --ap-translate: -100%;
            }

        }
        @media (max-width: 500px) {

            .ap {
                scale: .4;
                --ap-translate: -130%;
            }

        }

    }

    &-store {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        &-name {
            color: #194893;
            font-weight: 600;
        }
    }

    &-bar {
        height: 8rem;
        width: 1rem;
        border-radius: 4px;
        background: linear-gradient(0deg,
                hsla(118, 34%, 45%, 1),
                hsla(66, 47%, 58%, 1) 25%,
                hsla(48, 90%, 69%, 1) 50%,
                hsla(30, 89%, 67%, 1) 75%,
                hsla(357, 89%, 64%, 1) 100%);


        &-container {
            display: flex;
            align-items: stretch;
            position: absolute;
            bottom: 1rem;
            background-color: rgba(220, 220, 220, 0.267);
            border-radius: 3px;
            padding: 0.2rem;
            gap: 0.5rem;
        }

        &__symbols {
            display: flex;

            justify-content: space-between;
            flex-direction: column-reverse;

            p {
                margin: 0;
            }
        }
    }
}
.ap-container{
    position: absolute;
    height: 100%;
    width: 100%;
    
}

.ap {
    --ap-translate: -50%;
    transform: translate(var(--ap-translate), var(--ap-translate));
    height: 2rem;
    width: 2rem;
    border-radius: 50px;
    position: relative;
    z-index: 1;

    &:hover + .ap-info{
            display: block;
    }



    &-info {
        margin-left: 1.2rem;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 0 9px 2px #5f5f5f70;
        position: absolute;
        z-index: 10000;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        min-width: 8rem;
        display: none;
    }

    &-location,
    &-users {
        margin: 0;
    }

    &-location {
        font-weight: 700;
    }
}