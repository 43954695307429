.signout{
	//background:red;
	a{
		flex-flow: column;
		align-items: center;
		justify-content: center;
		color: rgba(239,241,245,.7);
		.p-menuitem-text{
			color: white !important;
			font-weight: 700;
		}
		span{
			font-size: 0.9em;
			font-size: 14px;
			color: rgba(239,241,245,.7);
		}
	}
	a:before{
		content: "Cerrar sesión";
		font-size: 14px;

	}
}

.sd .navbar-secundario{
	padding: 0em 1em;
	margin-left:0;
	margin-right:0;
	@media (min-width: 1024px) {
		padding: 0em 3em;
	}
	text-align: left;
	background: white;
	
	@include shadow(0px 3px 20px rgba(0, 0, 0, 0.1));
	.title-navbar{
		display: none;
		@include rem(font-size, 20);
		line-height: 1.1;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (min-width: 750px) {
			display: block;
		}
	}

	.header-btn-container{
		width: 100%;
	   	flex-basis: auto;
	   	text-align: center;
	   	.p-button{
	   		width: 100%;
	   		
	   	}
		@media (min-width: 600px) {
			text-align: right;
			flex-basis: 0;
			.p-button{
		   		width: auto;
		   	}
		}
	}
	.dato-header, .title-navbar{
		margin-right: 2em;
	}
	.dato-secundario{
		.dato{
			@include rem(font-size, 18);
			line-height: 1.3;
		}
		.label{
			@include rem(font-size, 14);
			color:$gray;
		}		
	}

    i.excedentes {
        color: $main-color-2 !important;
    }

}
.p-menubar.navbar-principal {
	position: relative;
	padding: 0.5em 1em;
	
	@media (min-width: 1024px) {
		padding: 0.5em 3em;
	}
	@include shadow(0px 3px 20px rgba(0, 0, 0, 0.1));
	.p-menubar-start{
		img{
			height: 44px;
			@media (min-width: 750px) {
				height: 64px;
			}
		}
	}
	&.blue-nav{
		background: linear-gradient(165deg,#2177f7e7,#1b4f9c);
		padding: 1em 2em;
		padding: 0;
		height: 104px;
		.p-menubar-start{
			padding: 1em 1em 1em 2em;
		}
		.p-submenu-list{
			font-size: 14px;
		}
		.notificaciones{
			.pi-angle-down{
				display: none;
			}
		}
		.p-submenu-list{
			padding:0;
		}
		.p-menubar-root-list{
			height: 100%;
			 > .p-menuitem{ 
				 height: 100%;
				> .p-menuitem-link{
					height: 100%;
					margin: 0 15px;
					padding: 6px 0;
					box-shadow: none;
					color: rgba(239,241,245,.7);
					.p-menuitem-text {
						color: rgba(239,241,245,.7);
						font-size: 14px;
						font-weight: 600;
						line-height: 26px;
						transition: all .3s;
					}
					.p-submenu-icon,.p-menuitem-icon {
						color: rgba(239,241,245,.7);
					}
					&:hover{
						.p-menuitem-text ,.p-submenu-icon, .p-menuitem-icon{
							color:white !important;
						}
						background-color: transparent;
					}
					&:hover, &:hover:not(.p-disabled):hover{
						background-color: transparent;
						color:white;
						> .p-menuitem-link{
							background-color: transparent;
							color:white;
						}
					}
				}
				&.p-menuitem-active, &.p-parent-active {
					> .p-menuitem-link{
						background-color: transparent;
						
						.p-menuitem-text ,.p-submenu-icon, .p-menuitem-icon{
							color: white !important;
					
						}
					}
				}
			}
		} 
	}
}

.dato-header{
	
	align-items:center;

	.dato{
		@include rem(font-size, 30);
		
		font-weight: 300;
		margin-right: 0.3em;
		color: $secondary-color-2;
	}
	.label{
		
		@include rem(font-size, 12);
		max-width: 70px;
		line-height: 1.2;
font-weight: 600;
	}
}

.notificaciones {
	
    &.p-menuitem-active {
        
    }
	.p-submenu-list{
		right:0;
	}
    & > .p-menuitem-link {
        position: relative;
		
        .p-menuitem-text {
            position: absolute;
            top: 30px;
            left: 8px;
            
            
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            //padding: .1rem .3rem;
			text-align: center;
            font-weight: 500;
            font-size: .75rem !important;
			width: 18px;
			height: 18px;
			line-height: 18px !important;
			background-color: $main-color-2;
			color:white !important;

        }
    }
    
    ul.p-submenu-list {
        width: 18rem;

        .p-menuitem-link {
            flex-direction: row-reverse;

            .p-menuitem-icon {
                color: $secondary-color-2 !important;
            }
        }
    }
}
