.statistics-main-container {
    display: grid;
    grid-template-columns: 2fr 10fr;
}


.statistics-menu-container {
    display: flex;
    width: 100%;
    gap: 2rem;
    margin-top: -8px;
    margin-left: -8px;

    div:first-child {
        flex: 1;
    }

    .vertical-divider {

        width: 10px;
        background: rgb(180, 180, 180);
    }

    .header-titulo-pagina {
        height: 62px;
    }

}

.p-menu.menu-statistics {
    border: none;
    background-color: unset;
    width: fit-content;
    min-width: 161px;
    margin-left: .75rem;

    .p-menuitem {
        .p-menuitem-link {
            color: #009d63;
            border-radius: 0.4rem;

            span {
                color: inherit;
                font-size: 0.825rem;
                font-weight: 600;
                line-height: 26px;
                transition: all 0.3s;
                display: block;
            }

            &:not(.p-disabled):hover {
                background: #006a43;

                span {
                    color: #fff;
                }
            }

            &:focus {
                box-shadow: none;
            }
        }

        &.active {
            .p-menuitem-link {

                background: #009d63;

                span {
                    color: #fff;
                }
            }
        }
    }

}

.looker-container {
    height: calc(100vh - 8.5rem);
    height: calc(100dvh - 8.5rem);
    overflow-y: auto;

    iframe {
        margin-top: -1rem;
        margin-left: .2rem;
        width: calc(100% + .5rem);
        border: none;
        height: calc(100% + 20px);
    }
}